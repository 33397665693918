import Home from "./home";
import React from "react";
import { Routes, Route } from "react-router-dom";
import History from "./pages/history";
import AboutUs from "./pages/AboutUs";
import Erico from "./pages/Errico";
import Sundayreflection from "./pages/Sundayreflection";
import OurCommunity from "./pages/OurCommunity";
import Vocations from "./pages/Vocations";
import Fcrareport from "./pages/Fcrareport";
import Contact from "./pages/contact";
import CalendarComponent from "./pages/Calendar";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/history" element={<History />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/erico" element={<Erico />} />
        <Route path="/sundayreflection" element={<Sundayreflection />} />
        <Route path="/ourcommunity" element={<OurCommunity />} />
        <Route path="/vocations" element={<Vocations />} />
        <Route path="/fcrareport" element={<Fcrareport />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/calendar" element={<CalendarComponent />} />
      </Routes>
    </>
  );
}

export default App;
