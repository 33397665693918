import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "../components/header";
import Footer from "../components/footer";
import ApiUrl from "../Api/Api";

function Contact() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [loaderVisible, setLoaderVisible] = useState(false);

  const recaptchaRef = useRef();


  const navigate = useNavigate();

  const onSubmitContactForm = (data, e) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setValue("recaptcha", "", { shouldValidate: true });
      setLoaderVisible(false);
      return;
    }

    setLoaderVisible(true);

    data.recaptchaValue = recaptchaValue;

    axios
      .post(`${ApiUrl}/store/contact`, data)
      .then((response) => {
        setLoaderVisible(false);
        if (response.status === 200) {
          Swal.fire(
            "Thank you for contacting us. We will get in touch with you shortly.",
            "",
            "success"
          );
          e.target.reset();
          navigate("/contact-us");
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: err.message,
        });
      });
  };

  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container" style={{ padding: "30px" }}>
          <h3 className="entry-title motherhouse">
            <a href="/contact-us">Contact Us</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;</Link>
            <span className="pagename">Contact Us</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <h3 className="heading">Contact Us</h3>
        <div className="row">
          <div className="col-lg-12">
            <div>
              <h5 className="entry-titlenew">Location:</h5>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.1217188889273!2d77.700584!3d13.02792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1057ed853839%3A0xb5bd3f81ddf7014c!2sMissionaries%20of%20the%20Sacred%20Hearts%20(%20M%20Ss%20Cc)!5e0!3m2!1sen!2sin!4v1707976054498!5m2!1sen!2sin"
                height={250}
                style={{ border: 0, width: " 100%" }}
                allowFullScreen
                title="st charles"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
        <h5 className="entry-titlenew">Contact Us:</h5>
        <div className="row">
          <div className="ecep bs-callout col-lg-6">
            <img src="images/subpage/alumni-3-1.jpg" alt="" />
            <ul>
              <li>
                <i className="fa fa-map-marker"></i>&nbsp; Regional Superior
              </li>
              <li> Missionaries of the Sacred Hearts,</li>
              <li>9, Alfred Street, Richmond Town,</li>
              <li> Bangalore – 560025 Karnataka, India.</li>
              <li>
                <i className="fa fa-phone"></i>&nbsp;&nbsp;
                <a href="tel:+91 080-22210331" style={{ color: "#6b1d2f" }}>
                  +91 080-22210331
                </a>
              </li>
              <li>
                <i className="fa fa-phone"></i>&nbsp;&nbsp;
                <a href="tel:+91 9591049400" style={{ color: "#6b1d2f" }}>
                  +91 9591049400
                </a>
              </li>
              <li>
                <i className="fa fa-envelope"></i>&nbsp;&nbsp;
                <a
                  href="mailto:sacredheartsfatherssociety@gmail.com"
                  style={{ color: "#6b1d2f" }}>
                  sacredheartsfatherssociety@gmail.com
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 bs-callout">
            <form
              className="php-email-form"
              onSubmit={handleSubmit(onSubmitContactForm)}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    {...register("name", { required: true })}
                    aria-invalid={errors?.name ? "true" : "false"}
                  />
                  {errors?.name?.type === "required" && (
                    <div className="text-danger text_error">
                      <label className="errlabel">Name is required</label>
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="email">Your Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    {...register("email", { required: true })}
                    aria-invalid={errors?.email ? "true" : "false"}
                  />
                  {errors?.email?.type === "required" && (
                    <div className="text-danger text_error">
                      <label className="errlabel">Email is required</label>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Your Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  id="mobile"
                  {...register("mobile", { required: true })}
                  aria-invalid={errors?.mobile ? "true" : "false"}
                />
                {errors?.mobile?.type === "required" && (
                  <div className="text-danger text_error">
                    <label className="errlabel">Mobile is required</label>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  rows={10}
                  defaultValue={""}
                  {...register("message", { required: true })}
                  aria-invalid={errors?.message ? "true" : "false"}
                />
                {errors?.message?.type === "required" && (
                  <div className="text-danger text_error">
                    <label className="errlabel">Message is required</label>
                  </div>
                )}
              </div>
              <div className="form-group">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LfiZxIpAAAAACmjf6CKmU16rxT9lqshGQw6hE7y"
                  onChange={(value) => {
                    setValue("recaptcha", value, { shouldValidate: true });
                  }}
                />
                {errors.recaptcha && (
                  <span className="text-danger">
                    Please complete the reCAPTCHA verification.
                  </span>
                )}
              </div>
              <div className="text-center">
                <button type="submit" className="buttonjs">
                  Send Message
                </button>

                <span
                  id="loader"
                  style={{ display: loaderVisible ? "inline-block" : "none" }}>
                  <img
                    src="images/subpage/gif/ajaxload.gif"
                    width="32px"
                    height="32px"
                    alt="Loader"
                  />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
