import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import NoImage from "../assets/img/no-image-available.png";
import { MssccUrl } from "./API/Api";

function Apidata() {
  // priest ordination api start

  const [ordinationData, setOrdinationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(`${MssccUrl}/member/province/ordination/1`).then((response) => {
      if (response?.data?.data != null) {
        const extractedData = response?.data?.data.map((item) => ({
          membername: item.member_name,
          dob: item.ordination_date,
          image: item.image,
        }));
        setOrdinationData(extractedData);
      }
      setIsLoading(false);
    });
  }, []);

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.toLocaleString("default", {
    month: "long",
  });

  const upcomingBirthdays = ordinationData.map((item) => {
    const dobParts = item.dob.split("-");
    const dobDay = parseInt(dobParts[2], 10);
    const dobMonth = parseInt(dobParts[1], 10) - 1;

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();

    const isFutureOrToday =
      dobMonth > currentMonth ||
      (dobMonth === currentMonth &&
        dobDay >= currentDay &&
        dobMonth === currentMonth &&
        dobDay < currentDay);

    return {
      ...item,
      isFutureOrToday: isFutureOrToday,
    };
  });

  const futureAndTodayBirthdays = upcomingBirthdays.filter(
    (birthday) => birthday.isFutureOrToday
  );

  const first5UpcomingBirthdays = futureAndTodayBirthdays.slice(0, 50);

  //newsesction start here

  const [upcomeData, setUpcomeData] = useState([]);

  useEffect(() => {
    axios
      .get(`${MssccUrl}/newsletter/province/1`)
      .then((response) => {
        if (Array.isArray(response?.data?.data)) {
          const currentDate = new Date();
          const data = response.data.data.map((event) => {
            const eventDate = new Date(
              event.date.split("-").reverse().join("-")
            );
            return { ...event, eventDate };
          });

          const filteredData = data.filter(
            (event) =>
              event.eventDate >= currentDate ||
              event.eventDate.toDateString() === currentDate.toDateString()
          );

          const sortedData = filteredData.sort(
            (a, b) => b.eventDate - a.eventDate
          );

          setUpcomeData(sortedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, []);

  function formatEventName(name, maxLength) {
    if (name.length <= maxLength) {
      return capitalizeEveryWord(name);
    } else {
      const truncatedName = name.slice(0, maxLength);
      return capitalizeEveryWord(truncatedName) + "...";
    }
  }

  function capitalizeEveryWord(str) {
    const words = str.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return capitalizedWords.join(" ");
  }
  return (
    <>
      <div className="home-page-events">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="upcoming-events">
                <div id="birthday-section-heading" className="section-heading">
                  <h2 id="birthday-entry-title" className="entry-title">
                    Ordination wishes
                  </h2>
                </div>
                <br />
                <div className="scrollable-content" id="birthfeast">
                  {isLoading ? (
                    <p>
                      <b>Loading...</b>
                    </p>
                  ) : first5UpcomingBirthdays.length > 0 ? (
                    first5UpcomingBirthdays.map((item, index) => {
                      const dobParts = item.dob;
                      const dobDay = parseInt(dobParts[0], 10);
                      // const dobMonth = dobParts[1].trim();
                      const dobMonth = dobParts[1];
                      const isBirthdayToday =
                        dobDay === currentDay && dobMonth === currentMonth;
                      const imageSrc = item.image ? item.image : NoImage;
                      return (
                        <div
                          className="event-wrap d-flex flex-wrap justify-content-flex-start"
                          key={index}
                        >
                          <img
                            style={{
                              borderRadius: "50%",
                              width: "13%",
                              height: "100%",
                              marginLeft: "2rem",
                            }}
                            src={imageSrc} // Changed to use imageSrc variable
                            alt=""
                          />
                          <div className="event-content-wrap" id="bfevent">
                            <header className="entry-header d-flex flex-wrap align-items-center">
                              <h3 className="entry-title w-100 m-0">
                                <Link
                                  to={"/"}
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.membername}
                                </Link>
                              </h3>
                              <div className="posted-date">
                                <Link
                                  to={"/"}
                                  style={{
                                    fontSize: "14px",
                                    color: "#6b1d2f",
                                  }}
                                >
                                  {item.dob}
                                </Link>
                              </div>
                              {isBirthdayToday && (
                                <img
                                  src="/images/graphics-happy-birthday.gif"
                                  alt="Happy Birthday GIF"
                                  className="birthday-gif"
                                />
                              )}
                            </header>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      <br />
                      No Ordination Wishes Available
                    </p>
                  )}
                  {/* <br />
                  <div className="text-center">
                    {first5UpcomingBirthdays.length > 0 && (
                      <Link
                        to={"calendar"}
                        className="btn-sm"
                        style={{
                          backgroundColor: "#6b1d2f",
                          color: "#e0ab08",
                          textDecoration: "none",
                        }}
                      >
                        View More
                      </Link>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="upcoming-events">
                <div className="section-heading">
                  <h2 className="entry-title">NewsLetter</h2>
                </div>
                <br />
                <div className="scrollable-content" id="provinceprog">
                  {upcomeData?.slice(0, 5).map((upcomeevent, index) => {
                    return (
                      <div
                        className="event-wrap d-flex flex-wrap justify-content-between"
                        key={index}
                      >
                        <img
                          src={
                            upcomeevent?.upload_image?.length > 0
                              ? upcomeevent.upload_image[0]
                              : NoImage
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                          }}
                          style={{
                            borderRadius: "50%",
                            width: "13%",
                            height: "100%",
                            marginLeft: "2rem",
                          }}
                          alt="Preview"
                        />
                        <div className="event-content-wrap" id="upcevent">
                          <header className="entry-header d-flex flex-wrap align-items-center">
                            <h3 className="entry-title w-100 m-0">
                              <Link
                                to={"/calendar?Iscal"}
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                }}
                              >
                                {formatEventName(upcomeevent.name, 69)}
                              </Link>
                            </h3>
                            <div className="posted-date">
                              <a
                                href="/calendar?Iscal"
                                style={{ fontSize: "14px", color: "#6b1d2f" }}
                              >
                                {upcomeevent.date}
                              </a>
                            </div>
                          </header>
                        </div>
                      </div>
                    );
                  })}
                  <br />
                  {upcomeData.length === 0 && (
                    <p
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      No News Letter Available
                    </p>
                  )}

                  {upcomeData.length > 5 && (
                    <div className="d-flex justify-content-center mt-4">
                      <Link
                        to={"/news-events"}
                        className="btn-sm"
                        style={{
                          backgroundColor: "#6b1d2f",
                          color: "#e0ab08",
                          textDecoration: "none",
                        }}
                      >
                        View More
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Apidata;
